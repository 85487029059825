import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { keyframes } from "@mui/system";

import Education from "./Education";
import Work from "./Work";
import Helmet from "react-helmet";

const Resume = () => {
  const content = `About Me!`;
  const speed = 40;
  const [displayedContent, setDisplayedContent] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    /*Create a new setInterval and store its id*/
    const animKey = setInterval(() => {
      setIndex((index) => {
        if (index >= content.length - 1) {
          clearInterval(animKey);
          return index;
        }
        return index + 1;
      });
    }, speed);
  }, [content.length]);

  useEffect(() => {
    setDisplayedContent(
      (displayedContent) => displayedContent + content[index]
    );
  }, [content, index]);

  const blink = keyframes`
   from{
       opacity: 0%;
    }
    to{
        opacity: 100%;
     }
`;
  const type = (
    <Box
      component="span"
      sx={{
        display: "inline-block",
        mx: "2px",
        transform: "scale(0.8)",
        animation: `${blink} 0.5s infinite`,
      }}
    >
      |
    </Box>
  );

  return (
    <Grid container>
      <Helmet>
        <title>About Me</title>
      </Helmet>
      <Grid item md={12} xs={12}>
        <Card sx={{ minHeight: "572px" }}>
          <CardHeader
            title={
              <Box sx={{ width: "20vw" }}>
                <Typography variant="h4" color={"#2B2D42"}>
                  {displayedContent}
                  {type}
                </Typography>
              </Box>
            }
          />
          <CardContent>
            <Education />
            <Work />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Resume;
