import { useEffect } from 'react';
import { Container, Grid } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";

import Profile from "./components/Profile/Profile";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Portfolio from "./pages/Portfolio/Portfolio";
import Resume from "./pages/Resume/Resume";
import Home from "./pages/Home";

import ReactGA from 'react-ga';

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";


function useGoogleAnalytics(){
  let location = useLocation();
  useEffect(() => {
    if(!window.GA_INITIALIZED){
      ReactGA.initialize('G-80DDG061TL');
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
  , [location]);
}


function App() {
  useGoogleAnalytics();

  return (
    <Container sx={{ paddingTop: "32px" }}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3} xs={12} sm={12} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
          <Profile />
        </Grid>
        <Grid item xl={9} lg={9} md={9} xs={12} sm={12} sx={{display: {xs:'block', sm: 'block', md: 'block' ,lg: 'block', xl: 'block'}}} >
          <Header />
          <Routes>
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/about" element={<Resume />} />
            <Route path="/" element={<Home />} />
          </Routes>
          <Footer />
        </Grid>
        <Grid item xl={3} lg={3} md={3} xs={12} sm={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }}>
          <Profile />
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
