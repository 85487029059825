import frontendUi from "../../assets/projects/images/frontendUI.jpg";
import cosmosimAR from "../../assets/projects/images/cosmosimAR.png";
import BuxBooster from "../../assets/projects/images/BuxBooster.png";
import Donocord from "../../assets/projects/images/Donocord.png";
import Anigame from "../../assets/projects/images/anigame.png";

const ProjectLists = [
  {
    title: "Frontend Code Generation",
    link: "https://youtu.be/IX9zQZeQG6w",
    linkAlias: "Frontend Code Generation",
    github:
      "https://github.com/Somoy73/Frontend-UI-Element-Detection-and-Classification",
    image: frontendUi,
    technology: "Python, Tensorflow, Keras, HTML, CSS, JS",
    description:
      "Detection and Classification of UI Elements of Web pages and Apps from Wireframe Sketches",
  },
  {
    title: "CosmosimAR",
    link: "https://youtu.be/_aNswtMFwwA",
    linkAlias: "CosmosimAR Video Demo",
    github: "https://github.com/Team-EON/theuniverse",
    image: cosmosimAR,
    technology: "Unity, C#, Vuforia SDK",
    description: "An interactive way to study the universe.",
  },
  {
    title: "BuxBooster",
    link: "https://chrome.google.com/webstore/detail/buxbooster/pbnnigbcmailepoeljnoelkheeicphpb",
    linkAlias: "BuxBooster Chrome Extension",
    github: "https://github.com/Somoy73/BuxBooster",
    image: BuxBooster,
    technology: "JavaScript, HTML, CSS",
    description:
      "Developed and published a stable chrome extension for enhancing the audio quality of class videos for BuX, the BRACU online education platform. Published on the Chrome Web Store.",
  },
  {
    title: "Donocord",
    link: "https://github.com/Somoy73/Donocord",
    linkAlias:
      "Donating in Social Organizations that Thrive to make the world a better place",
    github: "https://github.com/Somoy73/Donocord",
    image: Donocord,
    technology: "React, ExpressJS, NodeJs and MySQL",
    description:
      "Designed and Developed a small Full-stack website platform for donating to various welfare organization.",
  },
  {
    title: "AnigameTextBot",
    link: "https://github.com/Somoy73/AnigameTextBot",
    linkAlias: "AnigameTextBot",
    github: "https://github.com/Somoy73/AnigameTextBot",
    image: Anigame,
    technology: "Java, Tampermonkey, UserScript",
    description:
      "Created a full-automation bot for Discord’s text based games. Specifically designed for Anigame - a text based bot game in Discord.",
  },
];

export default ProjectLists;
