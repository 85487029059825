import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { keyframes } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

const Home = () => {  
  const content = `Hi! I am Somoy. 
                  Machine Learning x Software Engineer 
                  and a Potato 🥔`;
  const speed = 40;
  const [displayedContent, setDisplayedContent] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const animKey = setInterval(() => {
      setIndex((index) => {
        if (index >= content.length - 1) {
          clearInterval(animKey);
          return index;
        }
        return index + 1;
      });
    }, speed);

    return () => clearInterval(animKey);
  }, [content.length]);

  useEffect(() => {
    setDisplayedContent((displayedContent) => displayedContent + content[index]);
  }, [content, index]);

  const blink = keyframes`
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  const type = (
    <Box
      component="span"
      sx={{
        display: "inline-block",
        mx: "2px",
        transform: "scale(0.8)",
        animation: `${blink} 1s step-end infinite`,
        height: "1em",
        backgroundColor: "#2B2D42",
        width: "2px",
        verticalAlign: "middle",
        position: "relative",
        zIndex: 1
      }}
    >
      &nbsp;
    </Box>
  );

  return (
    <Grid container sx={{ height: "100%" }}>
      <Helmet>
        <title>Somoy's App</title>
      </Helmet>
      <Grid item xs={12}>
        <Card 
          sx={{ 
            minHeight: { xs: "50vh", md: "30vh" }, 
            maxHeight: { xs: "40vh", md: "80vh" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
            overflow: "visible",
            mx: "auto",
            width: { xs: "100%", md: "100%" }, 
          }}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <Box 
              sx={{ 
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                position: "relative",
                zIndex: 2,
                py: { xs: 4, md: 6 }
              }}
            >
              <Typography 
                variant="h4" 
                color="#2B2D42"
                sx={{ 
                  position: "relative",
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                  fontSize: { xs: "1.5rem", md: "2rem", lg: "2.5rem" } // Responsive font size
                }}
              >
                {displayedContent}
                {type}
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Stack
              spacing={{ xs: 2, md: 4 }} // Increased spacing on larger screens
              direction="row"
              sx={{
                width: "100%",
                justifyContent: "center",
                mb: { xs: 2, md: 4 } // More bottom margin on larger screens
              }}
            >
              <Button 
                component={RouterLink} 
                to="/about" 
                variant="outlined" 
                color="secondary"
                sx={{
                  px: { xs: 3, md: 6 }, // Wider padding on larger screens
                  py: { xs: 1, md: 2 }, // Taller on larger screens
                  minWidth: { xs: '120px', md: '200px' } // Wider buttons on larger screens
                }}
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Click',
                    label: 'About Me Button Clicked.'
                  });
                }}
              >
                <Typography 
                  variant="h5" 
                  color="#2B2D42"
                  sx={{ 
                    fontSize: { xs: '1.2rem', md: '1.5rem' } // Larger text on bigger screens
                  }}
                >
                  About Me
                </Typography>
              </Button>
              <Button 
                component={RouterLink} 
                to="/portfolio" 
                variant="outlined" 
                color="secondary"
                sx={{
                  px: { xs: 3, md: 6 }, // Wider padding on larger screens
                  py: { xs: 1, md: 2 }, // Taller on larger screens
                  minWidth: { xs: '120px', md: '200px' } // Wider buttons on larger screens
                }}
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Click',
                    label: 'Portfolio Button Clicked.'
                  });
                }}
              >
                <Typography 
                  variant="h5" 
                  color="#2B2D42"
                  sx={{ 
                    fontSize: { xs: '1.2rem', md: '1.5rem' } // Larger text on bigger screens
                  }}
                >
                  My Projects
                </Typography>
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Home;