import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardHeader,
  Grid,
  CardActions,
  IconButton,
  Collapse,
  Box,
} from "@mui/material";
import { keyframes } from "@mui/system";
import { styled } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import GitHubIcon from "@mui/icons-material/GitHub";

import ProjectLists from "./ProjectLists";

import { useEffect, useState } from "react";

import ReactGA from 'react-ga';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Projects = () => {
  const projectLists = ProjectLists.map((project) => project);
  const [expanded, setExpanded] = useState(projectLists?.map((item) => false));

  const content = `My Projects`;
  const speed = 200;
  const [displayedContent, setDisplayedContent] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    /*Create a new setInterval and store its id*/
    const animKey = setInterval(() => {
      setIndex((index) => {
        if (index >= content.length - 1) {
          clearInterval(animKey);
          return index;
        }
        return index + 1;
      });
    }, speed);
  }, [content.length]);

  useEffect(() => {
    setDisplayedContent(
      (displayedContent) => displayedContent + content[index]
    );
  }, [content, index]);

  const blink = keyframes`
   from{
       opacity: 0%;
    }
    to{
        opacity: 100%;
     }
`;
  const type = (
    <Box
      component="span"
      sx={{
        display: "inline-block",
        mx: "2px",
        transform: "scale(0.8)",
        animation: `${blink} 0.5s infinite`,
      }}
    >
      |
    </Box>
  );

  const handleExpandClick = (index) => {
    let newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);

    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Project Expanded - Project: ' + projectLists[index].title + ' .'
    });
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Box sx={{ width: "20vw" }}>
              <Typography variant="h4" color={"#2B2D42"}>
                {displayedContent}
                {type}
              </Typography>
            </Box>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            {projectLists?.map((project, index) => {
              return (
                <Grid item md={4} xs={12} key={index}>
                  <Card sx={{ ":hover": { boxShadow: 20 } }}>
                    <CardHeader title={project.title} sx={{ height: "10vh" }} />
                    <CardMedia
                      component="img"
                      height={"194"}
                      image={project?.image}
                      alt={project?.title}
                    />
                    <CardActions disableSpacing>
                      <IconButton
                        aria-label="View Project"
                        onClick={() => {
                          window.open(project?.link);
                          ReactGA.event({
                            category: 'Button',
                            action: 'Click',
                            label: 'View Project - Project: ' + project.title + ' .'
                          });
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        aria-label="Github Repo"
                        onClick={() => {
                          window.open(project?.github);
                          ReactGA.event({
                            category: 'Button',
                            action: 'Click',
                            label: 'Github Repo - Project: ' + project.title + ' .'
                          });
                        }}
                      >
                        <GitHubIcon />
                      </IconButton>
                      <ExpandMore
                        expand={expanded[index]}
                        onClick={() => handleExpandClick(index)}
                        aria-expanded={expanded[index]}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography variant="body1" color="text.secondary">
                          {project.description}
                        </Typography>
                        <Typography variant="body2" color={"text.secondary"}>
                          <br />
                          Technology Used:
                          {" " + project.technology}
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Projects;
