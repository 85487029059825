import React from "react";
import {
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
  //CardActions,
  Typography,
  Divider,
  CardActions,
  IconButton,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import { AiOutlineGitlab } from "react-icons/ai";

import profileImage from "./static/images/profile.png";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";

// const ProfileImageLazyLoad = () => (
//   <LazyLoadImage
//     alt="This is Me!"
//     height={200}
//     src={profileImage} // use normal <img> attributes as props
//     width={200}
//     effect="blur"
//   />
// );


const Profile = () => {
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  return (
    <Grid>
      <Card>
        <Grid container>
          <Grid item lg={12} md={12} sm={12}>
            <CardMedia
              component="img"
              loading="lazy"
              image={profileImage}
              alt="Somoy Subandhu"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <CardContent>
              <Typography variant="h3" colot="text.primary">
                Somoy
              </Typography>
              <Typography variant="h5" colot="text.secondary">
                Machine Learning + Software Engineer
              </Typography>
              <Divider />
              <Typography variant="body2" colot="text.secondary">
                {bull}AI-ML-DL {bull}Computer Vision {bull} DRL
              </Typography>
              <Divider />
              <Typography variant="body2" colot="text.secondary">
                {bull}Java {bull} C++ {bull}Python
              </Typography>
              <Divider />
              <Typography variant="body2" colot="text.secondary">
                {bull}Tensorflow {bull}Pytorch {bull}OpenCV
              </Typography>
              <Divider />
              <Typography variant="body2" colot="text.secondary">
                {bull}JavaScript {bull}TypeScript {bull}React
              </Typography>
              <Divider />
            </CardContent>
            <CardActions>
              <Divider />
              {/* <IconButton
                sx={{ paddingLeft: "64px" }}
                aria-label="GitLab"
                onClick={() =>
                  window.open("https://gitlab.com/Somoy.mainframelabs")
                }
              >
                <AiOutlineGitlab color="#FF7D00" />
              </IconButton> */}
              <IconButton
                aria-label="Github"
                onClick={() => window.open("https://github.com/Somoy73")}
              >
                <GitHubIcon sx={{ color: "#34113F" }} />
              </IconButton>
              <IconButton
                aria-label="LinkedIn"
                onClick={() =>
                  window.open("https://www.linkedin.com/in/somoy/")
                }
              >
                <LinkedInIcon sx={{ color: "#0B4F6C" }} />
              </IconButton>
            </CardActions>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default Profile;
