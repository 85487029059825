import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { workDetails } from "../../utils/cvDetails";

import { useState } from "react";

import ReactGA from 'react-ga';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Work = () => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Workplace Expanded'
    });
  };

  return (
    <Card sx={{ paddingBottom: "16px", marginBottom: "16px" }}>
      <CardHeader
        title={
          <Typography variant="h4" color={"#2E4057"}>
            Work Experience
          </Typography>
        }
        avatar={<WorkIcon sx={{ color: "#A188A6", fontSize: "120" }} />}
      />
      <CardContent>
        <Stack direction={"row"} spacing={2}>
          {workDetails?.map((work) => {
            return (
              <Box sx={{ paddingLeft: "16px" }} key={work.id}>
                <Avatar
                  src={work.logo ? work.logo : ""}
                  sx={{ width: 56, height: 56, border: "3px solid #66A182" }}
                />
              </Box>
            );
          })}
        </Stack>
      </CardContent>
      <CardActions>
        <Box sx={{ display: "flex", justifyContent: "left" }}>
          <Grid
            item
            md={8}
            xs={12}
            sx={{ paddingLeft: "16px", paddingTop: "8px" }}
          >
            {expanded ? (
              <Typography variant="body1" color={"#FF7D00"}>
                <b>Hide </b>
              </Typography>
            ) : (
              <Typography variant="body1" color={"#FF7D00"}>
                <b>Show </b>
              </Typography>
            )}
          </Grid>
          <Grid item md={4} xs={6} sx={{ paddingLeft: "16px" }}>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Grid>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {workDetails?.map((work) => {
            return (
              <Card
                key={work.id}
                sx={{ marginTop: "16px", ":hover": { boxShadow: 20 } }}
              >
                <CardHeader
                  title={
                    <Typography variant="h5" color="#AA4465">
                      <b>{work.title}</b>
                    </Typography>
                  }
                  avatar={
                    <Avatar
                      src={work.logo ? work.logo : ""}
                      sx={{
                        width: 56,
                        height: 56,
                        border: "3px solid #66A182",
                      }}
                    />
                  }
                />
                <CardContent>
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h6">
                        Company: {" " + work.company}
                      </Typography>
                      <Typography variant="h6">
                        Location: {" " + work.location}
                      </Typography>
                      <Typography variant="h6">
                        Duration: {" " + work.startDate + " to " + work.endDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  {work.companyUrl1 && (
                    <Button
                      variant="outlined"
                      sx={{ color: "#FF7D00", fontSize: "14px" }}
                      onClick={() => window.open(work.companyUrl1)}
                    >
                      {work.url1Alias}
                    </Button>
                  )}
                  {work.companyUrl2 && (
                    <Button
                      variant="outlined"
                      sx={{ color: "#FF7D00", fontSize: "14px" }}
                      onClick={() => window.open(work.companyUrl2)}
                    >
                      {work.url2Alias}
                    </Button>
                  )}
                </CardActions>
              </Card>
            );
          })}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default Work;
