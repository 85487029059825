import React from "react";
import { Grid } from "@mui/material";
import Projects from "../../components/Projects";
import Helmet from "react-helmet";



const Portfolio = () => {
  return (
    <Grid container>
      <Helmet>
        <title>My Projects</title>
      </Helmet>
      <Grid item md={12} xs={12}>
        <Projects />
      </Grid>
    </Grid>
  );
};

export default Portfolio;
