import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const resumeDetails = {
  socials: {
    Github: {
      link: "https://github.com/Somoy73",
      icon: <GitHubIcon />,
    },
    LinkedIn: {
      link: "https://www.linkedin.com/in/somoy/",
      icon: <LinkedInIcon />,
    },
  },
};
export default resumeDetails;
